import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { MAIN_CONTENT_ID } from '@/components/accessibility/SkipToMain';
import AuthForm from '@/components/authForm/AuthForm';
import { CustomPlaceholders } from '@/components/authForm/AuthForm/types';
import Header from '@/components/header/Header';
import Loader from '@/components/Loader';
import GridWrapper from '@/components/ui/GridWrapper';
import { useAppLoadedState, useAuthFormModeState } from '@/hooks/store';
import genericMessages from '@/messages/messages';
import getParamAsString from '@/utils/getParamsAsString';
import redirect, { protectOpenRedirect } from '@/utils/legacy/redirect';

import { Heading, Wrapper } from './styles';

const AuthPageWrapper = () => {
	const { query } = useRouter();
	const authFormMode = useAuthFormModeState();
	const appLoaded = useAppLoadedState();
	const { formatMessage } = useIntl();
	const [isCollege, setIsCollege] = useState(false);

	const onAuthSuccess = () => {
		if (isCollege) {
			redirect(`/college/verify?collegeName=${query.college_name}`);
		} else if (query?.continue) {
			const continueUri = getParamAsString(query.continue);
			const sanitizedContinueUri = protectOpenRedirect(continueUri);
			redirect(sanitizedContinueUri);
		} else {
			redirect('/app/profile/manage-subscription');
		}
	};

	const getCustomPlaceholders = (): CustomPlaceholders => {
		if (isCollege) {
			return isCollege ? { email: `Your ${query.college_name} Email` } : null;
		}

		return null;
	};

	useEffect(() => {
		if (query?.source === 'college-page') {
			setIsCollege(true);
		}
	}, [query]);

	return (
		<>
			<Header isStatic hideMenu hideProfile />
			<GridWrapper columns={4} id={MAIN_CONTENT_ID}>
				<Wrapper>
					{!appLoaded ? (
						<Loader color="gray5" />
					) : (
						<>
							<Heading>
								{formatMessage(
									authFormMode === 'signup' ? genericMessages.signupTitle : genericMessages.loginTitle,
								)}
							</Heading>
							<AuthForm
								onAuthSuccess={onAuthSuccess}
								isCollege={isCollege}
								customPlaceholders={getCustomPlaceholders()}
							/>
						</>
					)}
				</Wrapper>
			</GridWrapper>
		</>
	);
};

export default AuthPageWrapper;
